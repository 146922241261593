<template>
  <el-dialog :title="type == '01' ? '取消标记提示' : '标记提示'" :visible.sync="visible" :close-on-click-modal="false" width="550px" @closed="closed">
    <p style="text-align:center;margin-bottom: 25px;font-size: 22px">{{type == '01' ? '是否确认取消标记为厂家订单？' : '是否确认标记厂家订单？'}}</p>
    <el-form ref="formRef" :model="form" :rules="rules" label-width="100px" label-position="left">
      <!-- <el-form-item v-if="type == '00'" label="厂家" prop="manufacturersType">
        <el-radio-group v-model="form.manufacturersType">
          <el-radio v-for="item in typeList" :key="item.value" :label="item.value">{{ item.key }}</el-radio>
        </el-radio-group>
      </el-form-item> -->
      <el-form-item label="动态口令" prop="command">
        <el-input v-model="form.command" placeholder="请输入谷歌动态口令"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">取 消</el-button>
      <el-button size="small" type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: 'TagDia',
  data() {
    return {
      visible: false,
      row: {},
      type: '',
      form: {
        manufacturersType: '',
        command: ''
      },
      rules: {
        manufacturersType: [{ required: true, message: '请选择厂家', trigger: 'change' }],
        command: [{ required: true, message: '请输入动态口令', trigger: 'change' }],
      },
      typeList: []
    }
  },
  methods: {
    open(row, type) {
      this.row = row
      this.type = type
      this.form = {
        manufacturersType: '',
        command: ''
      }
      this.getTypes()
      this.$nextTick(() => {
        this.$refs['formRef']?.clearValidate()
        this.visible = true
      })
    },
    getTypes() {
      _api.dict({ catalogCode: 'manufacturers' }).then(res => {
        console.log(res.data)
        this.typeList = res.data
      })
    },
    close() {
      this.visible = false
    },
    closed() {},
    async confirm() {
      if (this.$refs['formRef']) await this.$refs['formRef'].validate()
      await _api.markManufacturers({
        ...this.form,
        type: this.type,
        matrixId: this.row.id
      })
      this.$message.success("操作成功");
      this.$emit('confirm')
      this.close()
    }
  }
}
</script>