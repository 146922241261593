<template>
  <el-dialog
    :title="enable == '01' ? '移除门店' : '添加门店'"
    v-loading="loading"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="1100px"
    @closed="close"
  >
    <el-form ref="formRef" :model="formData" inline label-width="80px" size="small" class="el-form-group_isRound">
      <el-form-item label="区域/总监" prop="inspectorId">
        <el-select v-model="formData.inspectorId" filterable clearable placeholder="请选择区域">
          <el-option v-for="item in areaList" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="门店名称" prop="nameOrAddress">
        <el-input v-model="formData.nameOrAddress" clearable placeholder="请输入商家名称查询" />
      </el-form-item>
      <el-form-item label="回收商数量" prop="merchantNum" label-width="100px">
        <el-input v-model="formData.merchantNum" clearable placeholder="请输入回收商数量" />
      </el-form-item>
      <el-button size="small" type="primary" round @click="getData()">查询</el-button>
    </el-form>
    <div class="export-box">
      <div>
        <el-checkbox :indeterminate="selectAllInfo.oddIndeterminate" v-model="selectAllInfo.odd" @change="(e) => handleCheckAllChange('odd', e)">选择奇数门店</el-checkbox>
        <el-checkbox :indeterminate="selectAllInfo.evenIndeterminate" v-model="selectAllInfo.even" @change="(e) => handleCheckAllChange('even', e)">选择偶数门店</el-checkbox>
      </div>
      <el-button :loading="excelLoading" type="success" size="mini" icon="el-icon-download" @click="storeExcle">导出Excel</el-button>
    </div>
    <el-table ref="tableRef" :data="list" border max-height="500px" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="40"></el-table-column>
      <el-table-column type="index" label="序号" align="center"></el-table-column>
      <el-table-column label="门店名称" prop="storeName" align="center"></el-table-column>
      <el-table-column label="门店地址" prop="storeAddress" align="center"></el-table-column>
      <el-table-column label="门店店长" prop="storeManager" align="center"></el-table-column>
      <el-table-column label="店长联系方式" prop="storeManagerPhone" align="center"></el-table-column>
      <el-table-column label="回收商名称" prop="inquiryMerchantName" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column label="回收商数量" prop="inquiryMerchantNum" align="center" width="90"></el-table-column>
      <el-table-column label="店员数量" prop="staffCount" align="center" width="80"></el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <template>
        <el-button @click.native="close">取消</el-button>
        <el-button type="primary" @click.native="confirm">{{enable == '01' ? '确定移除' : '确定添加'}}</el-button>
      </template>
    </span>
  </el-dialog>
</template>

<script>
import _api from "@/utils/request";
import { guideExcelAction } from "@/utils/common";
export default {
  name: 'LogisticsInquiryStatusDia',
  data() {
    return {
      visible: false,
      loading: false,
      row: {},

      enable: '01',
      excelLoading: false,
      formData: {
        inspectorId: '',
        nameOrAddress: '',
        merchantNum: ''
      },
      list: [],
      selectArr: [],
      areaList: [],
      selectAllInfo: {
        odd: false,
        oddIndeterminate: false,
        even: false,
        evenIndeterminate: false,
      }
    }
  },
  watch: {
    selectArr(v) {
      let selectAllInfo = {
        odd: false,
        oddIndeterminate: false,
        even: false,
        evenIndeterminate: false,
      }
      if (this.list.length) {
        if (v.length === this.list.length) {
          selectAllInfo.odd = true
          selectAllInfo.even = true
        } else {
          const oddArr = v.filter(item => item._index % 2)
          const evenArr = v.filter(item => !(item._index % 2))
          if (oddArr.length) {
            if (oddArr.length === this.oddArr.length) {
              selectAllInfo.odd = true
            } else {
              selectAllInfo.oddIndeterminate = true
            }
          }
          if (evenArr.length) {
            if (evenArr.length === this.evenArr.length) {
              selectAllInfo.even = true
            } else {
              selectAllInfo.evenIndeterminate = true
            }
          }
        }
      }
      this.selectAllInfo = selectAllInfo
    }
  },
  computed: {
    oddArr() {
      return this.list.filter(item => item._index % 2)
    },
    evenArr() {
      return this.list.filter(item => !(item._index % 2))
    }
  },
  methods: {
    open(row, enable) {
      this.row = row
      this.enable = enable
      this.$refs['formRef']?.resetFields()
      this.$nextTick(() => {
        this.getInspectorAreaList()
        this.getData()
        this.visible = true
      })
    },
    getData() {
      this.loading = true
      _api.storeInquiryMerchantList({
        ... this.formData,
        enable: this.enable,
        matrixId: this.row.matrixId,
        merchantId: this.row.merchantId,
      }).then(r => {
        this.list = (r.data || []).map((item, index) => {
          item._index = index + 1
          return item
        })
      }).finally(() => {
        this.loading = false
      })
    },
    getInspectorAreaList() {
      _api.inspectorAreaSelect({ companyId: this.row.companyId }).then(r => {
        this.areaList = (r.data || []).map(v => {
          return {
            value: v.staffId,
            label: (v.staffName ? v.staffName : '') + (v.areaName ? '-' + v.areaName : '')
          }
        })
      })
    },
    storeExcle() {
      this.excelLoading = true;
      _api.excelStoreInquiryMerchantList({
        ... this.formData,
        enable: this.enable,
        matrixId: this.row.matrixId,
        merchantId: this.row.merchantId,
      }).then((r => {
        guideExcelAction(r, '报价回收商门店列表', () => {
          this.excelLoading = false;
        });
      }))
    },
    handleSelectionChange(selectArr) {
      this.selectArr = selectArr
    },
    handleCheckAllChange(type, value) {
      const tableEl = this.$refs['tableRef']
      if (value) {
        if (type === 'odd') {
          this.oddArr.forEach(item => {
            tableEl.toggleRowSelection(item, true)
          })
        } else {
          this.evenArr.forEach(item => {
            tableEl.toggleRowSelection(item, true)
          })
        }
      } else {
        this.selectArr.forEach(item => {
          if (type === 'odd' && item._index % 2) {
            tableEl.toggleRowSelection(item, false)
          }
          if (type === 'even' && !(item._index % 2)) {
            tableEl.toggleRowSelection(item, false)
          }
        })
      }
    },
    optionsFilter(v, options) {
      return options.find(item => item.value == v)?.label
    },
    confirm() {
      _api.doStoreInquiryMerchant({
        enable: this.enable == '01' ? '02' : '01',
        matrixId: this.row.matrixId,
        merchantId: this.row.merchantId,
        storeIds: this.selectArr.map(item => item.id)
      }).then(() => {
        this.$message.success("操作成功");
        // 关闭弹窗
        this.visible = false;
        this.$emit('confirm')
      });
    },
    close() {
      this.visible = false;
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.export-box{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
</style>