<template>
  <el-dialog title="矩阵设置" :visible.sync="visible" :close-on-click-modal="false" width="550px" @closed="closed">
    <p style="color: #ff8080;margin-bottom: 25px;">说明：设置后，按照设置取价时间显示在员工确认交易页面，并进行定时执行</p>
    <el-form ref="formRef" :model="form" :rules="rules" label-width="110px" label-position="left">
      <el-form-item label="矩阵名称" prop="matrixName">
        <el-input v-model="form.matrixName" maxlength="15" placeholder="请输入矩阵名称，最多15个字" />
      </el-form-item>
      <el-form-item label="矩阵取价时间" prop="getPriceTime">
        <el-input v-model="form.getPriceTime" clearable placeholder="请输入取价时间，单位秒" @input="priceInput">
          <template slot="append">秒</template>
        </el-input>
      </el-form-item>
      <el-form-item label="动态口令" prop="command">
        <el-input v-model="form.command" placeholder="请输入谷歌动态口令"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">取 消</el-button>
      <el-button size="small" type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: 'SettingDia',
  data() {
    const timeValidator = (rule, value, callback) => {
      if (Number(value) < 60) {
        return callback(new Error('报价时间设置值必须在60秒以上'));
      }
      callback()
    }
    return {
      visible: false,
      row: {},
      oldBrand: [],
      form: {
        matrixName: '',
        getPriceTime: '',
        command: ''
      },
      rules: {
        matrixName: [{ required: true, message: '请输入矩阵名称', trigger: 'change' }],
        getPriceTime: [
          { required: true, message: '请输入取价时间', trigger: 'change' },
          { validator: timeValidator, trigger: 'blur' }
        ],
        command: [{ required: true, message: '请输入动态口令', trigger: 'change' }],
      }
    }
  },
  methods: {
    open(row) {
      this.row = row
      this.form = {
        matrixId: row.id,
        getPriceTime: row.getPriceTime,
        matrixName: row.matrixName,
        command: ''
      }
      this.$nextTick(() => {
        this.$refs['formRef']?.clearValidate()
        this.visible = true
      })
    },
    priceInput(v) {
      let value = Number(v);
      if (isNaN(value)) {
        return this.form.getPriceTime = 60;
      }
      if (value > 600) {
        return this.form.getPriceTime = 600;
      }
    },
    close() {
      this.visible = false
    },
    closed() {},
    async confirm() {
      if (this.$refs['formRef']) await this.$refs['formRef'].validate()
      await _api.matrixPrice(this.form)
      this.$message.success("操作成功");
      this.$emit('confirm')
      this.close()
      // _api.doSaveInPlatform({
      //   channel: row.channel,
      //   outMachineId: row.outMachineId,
      //   ...this.form
      // }).then(res => {
      //   this.$message({
      //     type: 'success',
      //     message: '添加成功!'
      //   });
      //   this.$emit('confirm')
      //   this.close()
      // })
    }
  }
}
</script>